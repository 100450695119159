
const DiwaliData24 = [
    {
        id: 1,
        imageSource: require("../../../Assets/Events/2024/Diwali_2024/Diwali_2024_1.webp")
    },
    {
        id: 2,
        imageSource: require("../../../Assets/Events/2024/Diwali_2024/Diwali_2024_2.webp")
    },
    {
        id: 3,
        imageSource: require("../../../Assets/Events/2024/Diwali_2024/Diwali_2024_3.webp")
    },
    {
        id: 4,
        imageSource: require("../../../Assets/Events/2024/Diwali_2024/Diwali_2024_4.webp")
    },
    {
        id: 5,
        imageSource: require("../../../Assets/Events/2024/Diwali_2024/Diwali_2024_5.webp")
    }
];

export default DiwaliData24;