import { NavLink } from "react-router-dom";
import BannerImg2 from "../../../Assets/Events/2023/Banner_Images/banner_21.webp";
import BannerImg3 from "../../../Assets/Events/2024/Banner_Images/Anniversary_Day_Banner.webp";
import BannerImg1 from "../../../Assets/Events/2024/Banner_Images/banner_Image_1.webp";
import BannerImg4 from "../../../Assets/Events/2024/Banner_Images/Christmas_Banner.webp";
import BannerImg5 from "../../../Assets/Events/2024/Banner_Images/Diwali_Banner.webp";


const Events2024Banner = [
    {
        id: 1,
        bannerImage: <NavLink to={"/republic-day-2024"} className="href"><img src={BannerImg1} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/republic-day-2024"} className="href">Republic Day</NavLink></h4>
    },
    {
        id: 2,
        bannerImage: <NavLink to={"/anniversary-day-2024"} className="href"><img src={BannerImg3} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/anniversary-day-2024"} className="href">Anniversary Day</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/diwali-2024"} className="href"><img src={BannerImg5} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/diwali-2024"} className="href">Diwali #2024</NavLink></h4>
    },
    {
        id: 3,
        bannerImage: <NavLink to={"/christmas-2024"} className="href"><img src={BannerImg4} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/christmas-2024"} className="href">Christmas #2024</NavLink></h4>
    },
    {
        id: 4,
        bannerImage: <NavLink to={"/birthday-celebration-2024"} className="href"><img src={BannerImg2} alt="" className='img-fluid' /></NavLink>,
        bannerPara: <h4><NavLink to={"/birthday-celebration-2024"} className="href">Birthday Celebrations</NavLink></h4>
    },
];

export default Events2024Banner;