import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../CSSBlogs/MainBlogsCSSFile.css";
import ChristmasData24 from '../../../../../../Data/Events/2024/ChristmasData24';

const Christmas2024 = () => {
    return (
        <>
            <section className="carousel_slider_section">
                <div className="container carouselSliderFirstInnerDiv">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <Swiper
                                effect={"coverflow"}
                                grabCursor={true}
                                centeredSlides={true}
                                slidesPerView={"auto"}
                                coverflowEffect={{
                                    rotate: 0,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 2,
                                    // slideShadows: true,
                                }}
                                // pagination={{
                                //     clickable: true,
                                //     dynamicBullets: true,
                                // }}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                navigation={true}
                                modules={[EffectCoverflow, Navigation, Autoplay]}
                                className="mySwiper"
                            >
                                {
                                    ChristmasData24.map((items) => {
                                        return (
                                            <SwiperSlide className="swiperSlide" key={items.id}>
                                                <div className="swiper-slide-img">
                                                    <img src={items.imageSource} alt="Avatar" className='nexus' />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blogs-heading-and-para-section-start-here">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-md-12 blog-heading-and-para-div">
                            <h1 className='christmas-heading'>CHRISTMAS AT HOLISTIC LEARNING #2024</h1>
                            <p>‘Oh! Jingle Bells, Jingle Bells….’ Holistic Learning was unwrapping the magic of Christmas, and we, the tribe, were busy decking the halls. We all put ourselves into the Christmas spirit with gifts, appreciation, fun, and various play activities.</p>
                            <p>One day before the celebration, Faisal and Gourish were decking the halls with twinkle and fairy lights, Christmas trees, jingle bells, and Christmas bubbles. Our Christmas mood started that day. The tribe became busy buying or making and wrapping gifts, and at the same point, they were curious to know who their Secret Santa was: Ho Ho Ho. Our tribal chief, Deepak, had planned for this surprise Secret Santa to make the event more fascinating.</p>
                            <p>Finally, the wait was over. We found gifts with chocolates and a little Santa doll in our respective desks wrapped with wishes and joy from Deepak. We also wrapped gifts for him and put all of the gifts on his desk without writing our names, and he had to guess who had kept which gifts. It was a very exciting moment when Deepak reflected on his observation and his knowledge of his employee skills; he identified all our gifts and made us happy.</p>
                            <p>The next activity was very brainstorming, where all secret Santas hid their secret gifts within the office premises and made crack codes to gear up the treasure hunt activity. One by one, we all cracked the codes; ah-ha, these were not easy. We all found our gifts, and Secret Santa unlocked our thirst for joy and happiness. The holistic Learning tribe started acknowledging others, appreciating their thoughtful gifts, enhancing their team bonds, and capturing moments to cherish forever.</p>
                            <p>As we all know, nothing brings people together like good food; we ordered our food from ‘The Bangalore Cafe’ to speed up the ‘josh’ of our Christmas party. Like butter to bread and ice to water, adding some paneer is always better. We ended our meal with chit-chat and Gulab Jamuns. </p>
                            <p>To refresh all the tribe members after the meal, we played “Head-Shoulder-Knee-Toe-Bottle.” Our inner child was coming out and grabbing the bottle first to win. Another activity, “flipping the cups,” was played among the tribe members. “Arranging the cups like Pyramid” was the next game. Umm…It was quite difficult to keep the balloons in the air while arranging the cups correctly. It was less competition and more fun for all of us.</p>
                            <p>The next teambuilding activity was the “Trust Walk.” One participant was blindfolded, and another participant was guiding them to reach the final destination, covering all the barriers. Other tribe members were making barriers and distracting the participants with songs, music, and wrong directions. Our bundle of joy and happiness was making Christmas Eve more exciting, and parallelly, our team was turning into a more cohesive and trustworthy team.</p>
                            <p>Time was tick-tocking, but we were not ready to say the party ‘Bye.’ We ordered Tea, Coffee and ‘Samosa.’ Savoring each sip, we spread love, joy, gratitude, and appreciation to each other. All’s well, and that ends well. We realized that Christmas is not a time nor a season but a state of mind. The day ends with ‘Jingle bells, jingle bells, jingle all the way. Oh! What fun it is to ride in a one-horse open sleigh. Hey! Jingle bells, jingle bells, jingle all the way.”</p>
                        </div>
                        <div className="col-md-6 blog-writer-div">
                            <p>Written By ~ Swarnali B</p>
                        </div>
                        <div className="col-md-6 blog-button-div">
                            <NavLink to={"/events/more-events"} className="href"><button><span className='icon'><FaArrowLeft /></span> View All Events</button></NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Christmas2024;