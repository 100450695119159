const JobProfileData = [
    {
        id: 1,
        value: "General Content Writer",
        profile: "General Content Writer"
    },
    {
        id: 2,
        value: "Technical Content Writer",
        profile: "Technical Content Writer"
    },
    {
        id: 3,
        value: "Website Content Writer",
        profile: "Website Content Writer"
    },
    {
        id: 4,
        value: "Digital Marketing Executive",
        profile: "Digital Marketing Executive"
    },
    {
        id: 5,
        value: "Web Developer (Front-end / Back-end)",
        profile: "Web Developer (Front-end / Back-end)"
    },
    {
        id: 6,
        value: "UI / UX Designer",
        profile: "UI / UX Designer"
    },
    {
        id: 7,
        value: "Data Entry Specialist",
        profile: "Data Entry Specialist"
    },
    {
        id: 8,
        value: "Freelancing Opportunity",
        profile: "Freelancing Opportunity"
    }
];

export default JobProfileData;